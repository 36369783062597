<template>
  <div class="caseindex-container">
    <header class="mycase-header">
      <van-nav-bar
          :title="titleName"
          left-arrow
          @click-left="handleBlack"
          safe-area-inset-top
      >
        <template #right>
          <icon-svg
              iconClass="iconsearch"
              @click="loadSearchCase"
              style="height: 24px; width: 24px; color: '#333333'"
          />
        </template>
      </van-nav-bar>
<!--      <div class="lv-one" v-if="active==0&&showLvOne">-->
<!--        <div class="lv-one-type">-->
<!--          <div :class="topType===1?'bc-color':''" @click="handleChoiceLvOne(1)">企业工地</div>-->
<!--          <div :class="topType===2?'bc-color':''" @click="handleChoiceLvOne(2)">推荐工地</div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- 二级分类 -->
      <div class="twotypeselect">
        <div class="articleType-all" v-if="twoTypeShow">
          <van-tabs
              @click="loadTwoType"
              v-model:active="twoTypeTab"
              swipe-threshold="1"
              color="#FF6E01"
              title-active-color="#FF6E01"
          >
            <van-tab v-if="chioceShow" title="精选"></van-tab>
            <van-tab
                v-for="it in allCategorys"
                :key="it.categoryPrimaryId"
                :title="it.materialCategoryName"
            ></van-tab>
            <!--            <van-tab v-if="showMore" title="更多"></van-tab>-->
          </van-tabs>
        </div>
        <div class="more-type" @click="handleMoreType" v-if="twoTypeShow">更多</div>
      </div>
      <!--      <div class="articleType-all" v-if="twoTypeShow">-->
      <!--        <van-tabs-->
      <!--          @click="loadTwoType"-->
      <!--          v-model:active="twoType"-->
      <!--          swipe-threshold="4"-->
      <!--          color="#FF6E01"-->
      <!--          title-active-color="#FF6E01"-->
      <!--        >-->
      <!--          <van-tab v-if="chioceShow" title="精选"></van-tab>-->
      <!--          <van-tab-->
      <!--            v-for="it in allCategorys"-->
      <!--            :key="it.typeId"-->
      <!--            :title="it.typeName"-->
      <!--          ></van-tab>-->
      <!--          <van-tab v-if="chioceShow" title="更多"></van-tab>-->
      <!--        </van-tabs>-->
      <!--      </div>-->
    </header>

    <article class="mycase-article" id="article">
      <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          style="min-height: 100%"
      >
        <van-list
            v-model:loading="loading"
            :finished="finished"
            offset="50"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <van-cell v-for="item in cases" :key="item.activityId">
            <div class="article-list">
              <div class="article-box" v-if="!item.isDeleted">
                <img
                    :src="item.headImgUrl ? `${imgUrl}/${item.headImgUrl}` : morenImg"
                    alt=""
                />
                <div class="article-info">
                  <div
                      class="article-info-top"
                      @click="loadToDetails(item.activityId)"
                  >
                    <span class="topping" v-if="item.isTop">置顶</span>
                    <span class="test-info">{{ item.title }}</span>
                  </div>
                  <p class="article-type" v-if="item.typeNames">{{ item.typeNames }}</p>
                </div>
                <icon-svg
                    iconClass="iconshare-2"
                    class="share"
                    @click="handleShare(item)"
                />
                <!-- <div class="share" v-if="artType===2" @click="handleShareWeChat(item)"></div> -->
                <!-- <div class="recommend-val" v-if="artType===2&&myArticle !== 3">推荐值<span>566679</span></div> -->
                <div
                    class="recommend-val"
                    v-if="active === 1"
                    @click="handleDelCase(item)"
                >
                  <icon-svg iconClass="icontrash-2" class="del-article-icon"/>
                </div>
                <div v-if="artType === 1" class="source-info">
                  <span>{{ item.createMerchantName }}</span>
                  <span>{{ item.createName }}</span>
                </div>
              </div>
              <div class="my_articles" v-if="!item.isDeleted && active === 1">
                <div>
                  <icon-svg iconClass="iconfile-text" class=""/>
                  <span>线索 {{ item.countOfClues }}</span>
                </div>
                <div>
                  <icon-svg iconClass="iconeye" class=""/>
                  <span>查看 {{ item.countOfViews }}</span>
                </div>
                <div>
                  <icon-svg iconClass="iconshare" class=""/>
                  <span>转发 {{ item.countOfForward }}</span>
                </div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </article>
    <footer class="mycase-footer">
      <van-tabbar v-model="active" active-color="#289FFF" @change="onChange">
        <van-tabbar-item>
          <icon-svg
              iconClass="iconweixuanzhong3"
              v-show="!choiceAll"
              class="allArt"
          />
          <icon-svg
              iconClass="iconxuanzhong3"
              v-show="choiceAll"
              class="allArt"
          />
          <span>活动列表</span>
        </van-tabbar-item>
        <van-tabbar-item>
          <icon-svg
              iconClass="iconhuodong"
              v-show="!choiceMy"
              class="allArt"
          />
          <icon-svg
              iconClass="iconhuodong-copy"
              v-show="choiceMy"
              class="allArt"
          />
          <span>我的活动</span>
        </van-tabbar-item>
      </van-tabbar>
    </footer>

    <!-- 删除 -->
    <div class="del-wrapper">
      <van-overlay :show="showDel" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p>删除</p>
            <p>确定删除该活动？</p>
            <van-grid clickable :column-num="2">
              <van-grid-item @click="showDel=false"
              ><span>取消</span></van-grid-item
              >
              <van-grid-item @click="handleConfirm"
              ><span>确定</span></van-grid-item
              >
            </van-grid>
          </div>
        </div>
      </van-overlay>
    </div>
    <!-- 弹出分享框 -->
    <div class="share_box">
      <div class="wrapperPoster" id="saveposter" style="z-index: 9999" v-if="showOverlay">
        <div class="poster-details" v-if="showImg" ref="imageWrapper">
          <img :src="posterPic" crossOrigin="anonymous"/>
          <div class="text-qrcode">
            <div class="user-info-tag">
              <div class="user-info">
                <img :src="headImgUrl">
                <div class="left-text">
                  <p>{{ userForm.name }}</p>
                  <p>{{ userForm.merchantName }}</p>
                </div>
              </div>
              <div class="user-tag">
                <span class="user-tag-item" v-for="(it,index) in userTags" :key="index">{{ it }}</span>
              </div>
            </div>
            <div class="qrCodeUrl-all">
              <div class="qrCodeUrl-cls">
                <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
              </div>
              <p>立即扫码</p>
              <p>了解更多内容</p>
            </div>
          </div>
        </div>
        <div v-else class="posterImg-hb">
          <img class="card-img" :src="imgSrc" ref="cardImgRef" />
        </div>
      </div>
      <van-share-sheet
          v-model:show="showShare"
          title="分享"
          :options="shareOptions"
          cancel-text="取消"
          @select="onSelect"
          safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>

    <!-- 遮罩层 -->
    <van-overlay :show="showType">
      <div class="wrapper" @click.stop>
        <div class="select-like-type">
          <icon-svg iconClass="iconx" class="cancel" @click="cancelLoadMore" />
          <p class="title">选择你喜欢的类别</p>
          <div class="grid-type">
            <div
                :class="['grid-type-item', box.includes(it) ? 'bgColor' : '']"
                v-for="it in moreTypeList"
                :key="it.typeId"
                @click="handleChoice(it)"
            >
              {{ it.materialCategoryName }}
            </div>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
          <div class="confirm" @click="loadConfirm">确认</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import {nextTick, reactive, ref, toRefs, watch} from "vue";
// import {
//   deleteCase,
//   findMyCaseByPage,
//   getCaseDetailsUrl,
// } from "../../api/case";

import {
  // findBuildSiteByPageApp,
  // findMyBuildSiteByPage,
  // deleteBuildSiteApp,
  // getBuildSiteDetailsUrl,
} from "../../api/buildSite"
import {
  findActivityPageApp,
  findMyActivityPageApp,
  updateActivity,
  getActivityDetailsUrl,
  reprint
} from "../../api/activity"
import {getMerchantMaterialFullCategory } from "../../api/articleManagement";
import { useRoute, useRouter } from "vue-router";
import {   shareActivityToMiniProgram,copyStr,loadImages } from "@/utils/commonUtil";
import { createPosterAppShare,getQrCodeUrl } from "@/api/common";
import {posterShare} from "@/utils/posterShare"
import { Toast } from "vant";
import html2canvas from "html2canvas";
import  {findPosterImg} from "@/api/file"
import {getMerchantManagementScope} from "../../api/huokeArticle";
import {getUserCardByPoster} from "@/api/user/userCard"

export default {
  name:'buildSite',
  activated(){
    document.getElementById('article').scrollTop = Number(sessionStorage.getItem('scrollTop'))
  },
  deactivated(){
    let scrollTop = document.getElementById('article').scrollTop
    sessionStorage.setItem('scrollTop',scrollTop)
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const imageWrapper = ref(null);
    const cardImgRef = ref(null);
    const state = reactive({
      title:'',
      userForm:{},
      userTags:[],
      headImgUrl:'',
      topType:1,
      showLvOne:false,
      showSharePoster:false,
      shareJumpUrl:'',
      posterId:'',
      imgSrc:'',
      qrCodeUrl:'',
      posterName:'',
      posterPic:'',
      sharePosterImg:'',
      showOverlay: false,
      showImg: true,
      shareOptions:[],
      defaultImg: require("@/assets/images/activity_default_img.jpg"),
      //=======================
      twoTypeTab:0,
      endIndex:10000,
      showType: false,
      chioceShow: false,
      box: [],
      moreTypeList: [],
      caseTypeStr: route.params.caseTypeStr ? route.params.caseTypeStr : "",
      allCategorys: [],
      twoTypeShow: false,
      choiceAll: true,
      choiceMy: false,
      finished: false,
      refreshing: false,
      loading: false,
      current: 1,
      myCurrent: 1,
      pages: 1,
      myPages: 1,
      total: 1,
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      morenImg: require("../../assets/images/activity_default_img.jpg"),
      showDel: false,
      showShare: false,
      activityId: "",
      buildItem: {},
      active: route.params.caseType ? Number(route.params.caseType) : 0,
      twoType: route.params.twoType ? Number(route.params.twoType) : 0,
      titleName: "",
      showMycase: 1,
      cases: [],
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
        { name: "分享海报", icon: require("@/assets/icons/share_poster.png")}
      ],
      posterOptions: [
        {name: "微信分享", icon: require("@/assets/icons/weChat2.png")},
        {name: "朋友圈分享", icon: require("@/assets/icons/friends2.png")},
      ],
      caseParams: {},
    });
    // Toast.allowMultiple
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    watch(
        () => state.active,
        () => {
          if (state.active === 0) {
            state.titleName = "营销活动";
            state.choiceAll = true;
            state.choiceMy = false;
          } else {
            state.titleName = "我的活动";
            state.choiceMy = true;
            state.choiceAll = false;
            state.twoTypeShow = false;
          }
        }
    );
    watch(() => state.showShare, (newVal, oldVal) => {
      console.log('新值：',newVal,'老值',oldVal)
      if (state.showShare===false){
        state.showOverlay = false
        state.showImg = true
      }
    })
    if (state.active === 0) {
      state.titleName = "营销活动";
      state.choiceAll = true;
      state.choiceMy = false;
    } else {
      state.titleName = "我的活动";
      state.choiceMy = true;
      state.choiceAll = false;
      state.twoTypeShow = false;
    }
    const getMerchantManagement = async () =>{
      let res = await getMerchantManagementScope()
      state.showLvOne = res
      console.log(res)
      if (!res){
        state.topType = 0
      }
      // loadArticleMoreType();
    }
    getMerchantManagement()
    const handleChoiceLvOne = (val) =>{
      state.topType = val
      state.twoType = 0
      state.current = 1
      state.pages = 1
      state.cases = []
      loadCaseCategory()
      onLoad();
      state.finished = false
    }
    //加载分类
    const loadCaseCategory = async () => {
      let res = await getMerchantMaterialFullCategory({
        modeId:6,
        materialType:0,
      });
      // state.allCategorys = res.slice(0, 3);
      state.allCategorys = res
      // state.endIndex = res.length + 1
      state.moreTypeList = res;

      if (state.active === 0) {
        state.twoTypeShow = true;
      } else {
        state.twoTypeShow = false;
      }
      state.chioceShow = true;
    };
    loadCaseCategory();
    //关闭更多分类
    const cancelLoadMore = () => {
      state.showType = false;
    };

    //更多分类选择
    const handleChoice = (it) => {
      if (state.box.includes(it)) {
        state.box.splice(state.box.indexOf(it), 1);
        state.caseTypeStr = state.caseTypeStr
            .split(`${it.categoryPrimaryId.toString()},`)
            .join("");
      } else {
        state.box.push(it);
        if (state.caseTypeStr === "") {
          state.caseTypeStr = it.categoryPrimaryId.toString() + ",";
        } else {
          state.caseTypeStr = state.caseTypeStr + it.categoryPrimaryId.toString() + ",";
        }
      }
    };
    //更多分类确认
    const loadConfirm = () => {
      state.cases = []
      state.twoTypeTab = 0
      onLoad();
      state.showType = false;
    };
    //打开更多分类查询
    const handleMoreType = () =>{
      loadTwoType(10000)
    }
    //二级分类
    const loadTwoType = (val) => {
      console.log("二级分类", state.twoType);
      console.log("二级分类", val);
      if(val!==0&&val!==10000){
        state.allCategorys.map((it,index)=>{
          if(index+1===val){
            console.log("选中的分类",it)
            state.twoType = it.categoryPrimaryId
          }
        })
      }else {
        if(val===0){
          state.twoType = 0
        }else {
          state.twoType = val
        }
      }
      state.current = 1
      state.pages = 1
      if (state.twoType === state.endIndex) {
        state.showType = true;
        state.box = [];
        state.caseTypeStr = "";
      } else {
        state.cases = []
        onLoad();
        state.finished = false
      }
    };
    //刷新
    const onRefresh = async () => {
      state.cases = [];
      state.loading = false;
      if (state.active === 0) {
        state.pages = 1;
        state.current = 1;
      } else if (state.active === 1) {
        state.myPages = 1;
        state.myCurrent = 1;
      }
      onLoad();
      state.finished = false
      state.refreshing = false;
    };

    //加载更多
    const onLoad = async () => {
      let caseParams = {};
      if (state.active === 0) {
        caseParams.isMaterial = 1;
        caseParams.current = state.current;
        caseParams.category = state.twoType;
        caseParams.materialType = 0;
        if (state.twoType === 0) {
          caseParams.category = "";
        } else if (state.twoType === state.endIndex) {
          caseParams.category = state.caseTypeStr;
        }
        state.loading = true;
        let res = await findActivityPageApp(caseParams);
        state.pages = res.pages;
        if (res.records.length === 0||state.current>state.pages) {
          state.finished = true;
        } else {
          state.total = res.total;
          state.current = res.current;
          state.pages = res.pages;
          if (state.cases.length === 0) {
            state.cases = res.records;
            state.finished = false;
          } else {
            if (state.cases.length >= state.total) {
              state.finished = true;
            } else {
              state.cases.push(...res.records);
            }
          }
          state.current += 1
        }
        state.loading = false;
      } else {
        if (state.myCurrent >= state.myPages) {
          state.finished = true;
        } else {
          state.myCurrent = state.myCurrent + 1;
        }
        state.loading = true;
        let res = await findMyActivityPageApp({ current: state.myCurrent });
        state.myPages = res.pages;
        if (res.records.length === 0) {
          state.finished = true;
        } else {
          state.total = res.total;
          state.myCurrent = res.current;
          if (state.cases.length === 0) {
            state.cases = res.records;
            state.finished = false;
          } else {
            if (state.cases.length >= state.total) {
              state.finished = true;
            } else {
              state.cases.push(...res.records);
            }
          }
        }
        state.loading = false;
      }
      if (state.cases.length >= state.total) {
        state.finished = true;
      }
      Toast.clear()
    };
    //一级分类
    const onChange = () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      state.cases = [];
      if (state.active === 1) {
        state.twoTypeShow = false;
        state.pages = 1;
        state.current = 1;
      } else {
        state.myPages = 1;
        state.myCurrent = 1;
        state.twoTypeShow = true;
      }
      onLoad();
      state.finished = false;
    };
    //详情
    const loadToDetails = (activityId) => {
      console.log("id==================",activityId)
      router.push({
        path: "/activity/details",
        name:"activityDetails",
        query: {
          activityId: activityId,
          caseType: state.active,
          twoType: state.twoType,
          caseTypeStr: state.caseTypeStr,
        },
        params:{
          fromPoster:route.params.fromPoster==='true'?route.params.fromPoster:'',
          addPoster:route.params.addPoster==='true'?route.params.addPoster:''
        }
      });
    };
    const loadPoster = ()=>{
      savePoster()
      setTimeout(()=>{
        Toast.clear()
      })
    }
    //打开分享微信
    const handleShare = (item) => {
      console.log("活动详情",item)
      state.activityId = item.activityId;
      state.buildItem = item;
      state.shareOptions  = state.options
      getUserCardByPoster().then(res=>{
        if (res){
          if (res.headImgUrl){
            state.headImgUrl = state.imgUrl+'/'+res.headImgUrl
          }else {
            state.headImgUrl = require("@/assets/poster_headerImg_default.png")
          }
          state.userForm = res
          state.userTags = res.impressionLabel.split(',')
          if (state.userTags.length>4){
            state.userTags = state.userTags.slice(0,4)
          }
        }
      })
      console.log("案例详情===============",item)
      const p = {
        busiId:item.activityId,
        busiCat:'activity',
        groupId:'activityPosterPic',
      }
      findPosterImg(p).then((res)=>{
        console.log("海报图片==============》",res)
        if (res.length>0){
          //获取小图
          findPosterImg({
            busiId:item.activityId,
            busiCat:'activity',
            groupId:'activityPosterPic:S',
          }).then((sImg)=>{
            state.sharePosterImg = sImg[0].url?state.imgUrl+"/"+sImg[sImg.length-1].url:"https://wca.ujiaku.com/upload/wx/activity_default_img.jpg"
          })
          state.posterPic = res[0].url?state.imgUrl+"/"+ res[0].url:state.defaultImg
          // state.sharePosterImg = res[0].url?state.imgUrl+"/"+res[0].url:"https://wca.ujiaku.com/upload/wx/buildSite_default.png"
        }else {
          state.posterPic = state.defaultImg
          state.sharePosterImg = "https://wca.ujiaku.com/upload/wx/activity_default_img.jpg"
        }
        state.showShare = true;
      })
      // state.posterPic = item.headImgUrl?state.imgUrl+"/"+item.headImgUrl:state.defaultImg
      // state.sharePosterImg = item.headImgUrl?state.imgUrl+"/"+item.smallCoverPic:"https://wca.ujiaku.com/upload/wx/buildSite_default.png"
      state.posterName = item.title
      let params = {
        sourceId:item.activityId,//分享的id
        sourceType:6,//来源类别 1-文章 2-案例 3-视频 4-工地
      }
      getQrCodeUrl(params).then((res)=>{
        state.shareJumpUrl = res.jumpUrl
        if(res.qrCodeUrl){
          state.qrCodeUrl = res.qrCodeUrl
        }
        state.showSharePoster = true
      })
    };
    //微信分享
    const onSelect = (index) => {
      if(index.name === "微信分享"||index.name === "朋友圈分享"){
        if(state.showSharePoster===true){
          Toast.loading({
            message: '正在\n打开微信...',
            forbidClick: true,
            duration:3000,
            className:'posterToast',
          });
          let p = {
            sourceId: state.activityId,//分享的id
            sourceType:6,//来源类别 1-文章 2-案例 3-视频 4-工地
            jumpUrl:state.shareJumpUrl
          }
          createPosterAppShare(p).then((res)=>{
            console.log("创建二海报",res)
          })
          let param = {
            imgSrc:state.imgSrc,
            id:state.activityId,
            bigPosterImgUrl:state.sharePosterImg,
          }
          setTimeout(()=>{
            posterShare(index,param)
          })
        }
      }
      else if(index.name === "复制链接"){
        getActivityDetailsUrl(state.activityId).then((res)=>{
          copyStr(res)
          Toast.success("复制成功")
          state.showShare = false
        })
      }else if(index.name === "分享海报"){
        if(state.showSharePoster===true){
          Toast.loading({
            message: '生成海报中...',
            forbidClick: true,
            duration:0,
            className:'posterToast',
          });
          state.showOverlay = true;
          state.shareOptions = []
          state.shareOptions = state.posterOptions
          // setTimeout(()=>{
          //   savePoster()
          // },200)
          let arr = []
          arr.push(state.posterPic)
          arr.push(state.qrCodeUrl)
          arr.push(state.headImgUrl)
          loadImages(arr,loadPoster)
        }
      }else {
        reprint({ activityId: state.activityId });
        shareActivityToMiniProgram(state.buildItem, index);
      }
    };
    //删除
    const handleDelCase = (row) => {
      state.showDel = !state.showDel;
      state.activityId = row.activityId;
      state.title = row.title
    };
    //确认删除
    const handleConfirm = async () => {
      // if (isDel === "cancel") {
      //   state.showDel = !state.showDel;
      // } else {
        let p = {
          activityId:state.activityId,
          title:state.title,
          isDeleted:1
        }
        let result = await updateActivity(p);
        if (result) {
          findMyActivityPageApp({ size: state.myCurrent*10 }).then((res) => {
            state.cases = res.records;
            state.showDel = !state.showDel;
            Toast.success("删除成功");
          });
        }
      // }
    };

    const handleBlack = () => {
      // router.back()
      if (route.params.addPoster === 'true'){
        router.push('/createPoster')
        return
      }
      if (route.params.fromPoster === 'true'){
        router.push('/posterDetails')
        return
      }
      if (route.params.fromPoster){
        // router.push({ path: "/posterDetails" })
        router.back()
      }else {
        router.push({ path: "/" });
      }
    };
    //搜索
    const loadSearchCase = () => {
      router.push({
        path: "/activity/search",
        query: {
          caseType: state.active,
          twoType: state.twoType,
          caseTypeStr: state.caseTypeStr,
        },
      });
    };
    //画图
    const savePoster = () =>{
      console.log("保存")
      state.showOverlay = true;
      // html2canvasChange()
      nextTick(() => {
        // var canvas2 = document.createElement("canvas");
        let _canvas = imageWrapper.value;
        const box = window.getComputedStyle(_canvas);
        // DOM 节点计算后宽高
        const width = parseInt(box.width, 10);
        const height = parseInt(box.height, 10);
        // 获取像素比
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        html2canvas(_canvas, {
          useCORS: true,
          background: "#ffffff",
          height: document.getElementById('saveposter').scrollHeight,
          windowHeight: document.getElementById('saveposter').scrollHeight,
          canvas,
        }).then((canvas) => {
          var context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let dataURL = canvas.toDataURL("image/png");
          state.showImg = false
          nextTick(() => {
            cardImgRef.value.style.width = width + "px";
            cardImgRef.value.style.height = height + "px";
            state.imgSrc = dataURL;
            state.showImg = false
          });
        });
      });
    }
    const DPR = () => {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    };
    return {
      handleChoiceLvOne,
      imageWrapper,
      cardImgRef,
      savePoster,
      DPR,
      onRefresh,
      onLoad,
      ...toRefs(state),
      handleDelCase,
      handleConfirm,
      handleShare,
      onChange,
      handleBlack,
      loadSearchCase,
      loadToDetails,
      onSelect,
      loadTwoType,
      loadCaseCategory,
      cancelLoadMore,
      handleChoice,
      loadConfirm,
      handleMoreType,
    };
  },
};
</script>
<style lang="scss">
.posterToast{
  //color: #289fff;
  z-index: 1000000001 !important;
}
.caseindex-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  //遮罩层
  .wrapperPoster{
    //margin-top: 100px;
    height: 400px;
    overflow-x: hidden;
    //position: absolute;
    position: fixed;
    top: 60px;
    //bottom: 0;
    left: 0;
    right: 0;
    .poster-details{
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      overflow-x: hidden;
      >img{
        width: 250px;
        display: block;
      }
      .text-qrcode{
        background-color: #ffff;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        flex: 1;
        .user-info-tag{
          .user-info{
            display: flex;
            align-items: center;
            img{
              border-radius: 20px;
              width: 40px;
              height: 40px;
              object-fit: cover;
              flex-shrink: 0;
            }
            .left-text{
              text-align: left;
              //flex: 1;
              margin-left: 4px;
              p {
                margin: 0;
                padding: 0;
              }
              p:nth-child(1){
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                color: #333333;
              }
              p:nth-child(2){
                font-size: 10px;
                color: #333333;
                //line-height: 20px;
              }
            }
          }
          .user-tag{
            display: flex;
            flex-wrap: wrap;
            .user-tag-item{
              margin-top: 5px;
              margin-right: 5px;
              //padding-left: 5px;
              padding:2px 5px;
              color: #289FFF;
              background-color: #E6ECFF;
              font-size: 9px;
              border-radius: 4px;
            }
          }
        }


        .qrCodeUrl-all{
          //position: relative;
          //text-align: center;
          //height: 60px;
          border-left: 1px solid #F1F3F6;
          padding-left: 18px;
          margin-left: 8px;
          flex-shrink:0;
          .qrCodeUrl-cls{
            //width: 60px;
            //height: 60px;
            background-color: #FFFFFF;
            border-radius: 30px;
            //position: relative;
            //top: -33px;
            //text-align: center;
            img{
              width: 60px;
              //border-radius: 25px;
              //position: relative;
              //margin-top: 50%;
              //transform: translateY(-50%);
            }
          }
          >p{
            //position: relative;
            text-align: center;
            font-size: 10px;
            font-weight: 400;
            color: #666666;
            margin: 0;
            //top: -40px;
          }
        }
      }
    }
    .posterImg-hb{
      height: 400px;
      overflow-x: hidden;
    }
  }
  .van-overlay {
    z-index: 3;
    height: 100%;
    width: 100%;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .select-like-type {
        position: absolute;
        height: 365px;
        width: 344px;
        left: 16px;
        top: 88px;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 8px;
        background-color: #fff;
        .cancel {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 12px;
          right: 12px;
        }
        .title {
          font-size: 18px;
          font-weight: 600;
          line-height: 10px;
        }
      }
      .grid-type {
        padding-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        // border-radius: 10px;
        justify-content: space-between;
        >i{
          height: 28px;
          width: 72px;
        }
        .grid-type-item {
          height: 28px;
          width: 72px;
          margin-bottom: 8px;
          border-radius: 5px;
          font-size: 12px;
          line-height: 28px;
          background-color: #f1f3f6;
        }
        .bgColor {
          color: #ffffff;
          background-color: #ffcf23;
        }
      }
      .confirm {
        position: absolute;
        height: 28px;
        width: 72px;
        color: #ffffff;
        font-size: 12px;
        line-height: 28px;
        background-color: #ff6e01;
        border-radius: 5px;
        margin-left: 50%;
        left: -36px;
        // transform:translateX(-50%);
        bottom: 20px;
      }
    }
  }
  .mycase-header {
    font-size: 18px;
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .twotypeselect{
      display: flex;
      justify-content: space-between;
      .articleType-all {
        overflow-x: hidden;
        .van-tabs__wrap {
          border-bottom: 0.5px solid #f1f3f6;
        }
        .van-tab {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
          padding: 0 20px;
        }
        .van-tab--active {
          color: #323233;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .more-type{
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        color: #666666;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        line-height: 45px;
        padding: 0 10px;
      }
    }
    .lv-one{
      padding:0 16px;
      font-size: 14px;
      font-weight: 600;
      .lv-one-type{
        display: flex;
        justify-content: space-between;
        height: 38px;
        >div{
          line-height: 38px;
        }
        :nth-child(1){
          border-radius: 4px 0px 0px 4px;
          border: .4px solid #999999;
          flex: 1;
        }
        :nth-child(2){
          flex: 1;
          border-radius: 0px 4px 4px 0px;
          border: .4px solid #999999;
        }
        .bc-color{
          background-color: #FF6E01;
          color: #FFFFFF;
          border: none;
        }
      }
    }
  }
  .mycase-article {
    flex: 1;
    overflow-x: hidden;
    //  margin-bottom: 16px;
    .van-cell {
      padding: 0;
    }
    .van-cell::after {
      border: none;
    }
    .mycase-box-info {
      flex: 1;
      padding: 5px 16px;
      .case-box {
        font-size: 16px;
        text-align: left;
        .cover-img {
          height: 160px;
          > img {
            display: block;
            border-radius: 4px;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .case-info {
          //  height: auto;
          position: relative;
          display: flex;
          width: 343px;
          justify-content: space-between;
          .title {
            //  float: left;
            position: relative;
            width: 330px;
            > p {
              margin: 0;
              padding: 0;
            }
            > p:nth-child(1) {
              font-weight: 600;
              margin-top: 8px;
            }
            > p:nth-child(2) {
              font-weight: 400;
              font-size: 14px;
              margin: 4px 0;
              color: #666666;
            }
          }
          .share-del {
            top: 10px;
            position: relative;
            text-align: center;
            .share {
              height: 20px;
              width: 20px;
            }
            .del {
              height: 20px;
              margin-top: 10px;
              width: 20px;
              color: #ff5c4b;
            }
          }
        }
      }
      .case-type {
        margin: 0;
        padding: 3px 8px;
        // width: 70px;
        font-size: 11px;
        line-height: 20px;
        text-align: center;
        border-radius: 5px;
        color: #999999;
        border: 1px solid #999999;
      }
    }
    .my_case {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 34px;
      border-bottom: 1px solid #f1f3f6;
      border-top: 1px solid #f1f3f6;
      font-size: 12px;
      > div {
        height: 34px;
        display: flex;
        color: #999999;
        align-items: center;
        img {
          margin-right: 3px;
        }
      }
    }
  }

  .mycase-footer {
    height: 50px;
    .van-tabbar-item__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      .allArt {
        display: block;
        text-align: center;
        font-size: 20px;
      }
    }
  }
  //  删除
  .del-wrapper {
    .van-overlay {
      z-index: 102;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        font-size: 14px;
        width: 303px;
        height: 133px;
        border-radius: 10px;
        color: #333333;
        background-color: #fff;
        > div {
          > div:nth-child(1) {
            .van-hairline {
              border-radius: 0 0 0 10px;
              padding: 0;
              color: #999999;
            }
          }
          > div:nth-child(2) {
            .van-hairline {
              border-radius: 0 0 10px 0;
              padding: 0;
            }
            [class*="van-hairline"]::after {
              border-right: none;
            }
          }
        }
        [class*="van-hairline"]::after {
          border-bottom: none;
        }
        > p {
          font-weight: 600;
          font-size: 14px;
          margin: 17px 16px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 46px;
          // height: 100%;
          // width: 100%;
        }
      }
    }
  }
  //   分享
  .article-list {

    .my_articles {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 34px;
      border-bottom: 1px solid #f1f3f6;

      > div {
        height: 34px;
        display: flex;
        color: #999999;
        align-items: center;
      }
    }
  }

  .article-box {
    display: flex;
    height: 85px;
    position: relative;
    align-items: center;
    border-bottom: 0.5px solid #f1f3f6;
    padding: 0 16px;
    > img {
      display: block;
      height: 68px;
      width: 68px;
      border-radius: 8px;
      margin-right: 6px;
      object-fit: cover;
    }

    .article-info {
      height: 68px;
      width: 247px;
      text-align: left;
      font-size: 12px;
      position: relative;

      .article-info-top {
        height: 48px;
        width: auto;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        .topping {
          height: 20px;
          width: 40px;
          text-align: center;
          float: left;
          position: relative;
          left: 0;
          background: linear-gradient(0deg, #ff6e01, #ff6e01);
          border-radius: 4px 0px;
          margin-right: 4px;
        }

        .material {
          height: 20px;
          width: 60px;
          float: left;
          margin-right: 3px;
          text-align: center;
          background: #ffcf23;
          border-radius: 4px 0px;
        }
        .test-info {
          color: #333333;
          font-size: 16px;
          font-weight: 600;
          word-break: break-all;
        }
      }
      .article-type {
        display: block;
        height: 14px;
        text-align: center;
        line-height: 14px;
        padding: 5px 2px;
        transform: scale(0.85);
        position: absolute;
        bottom: -14px;
        color: #999999;
        border: 1px solid #999999;
        padding: 3px 5px;
        border-radius: 5px;
      }
    }
    .share {
      position: relative;
      top: -18px;
      right: 0;
      width: 24px;
      height: 24px;
    }

    .recommend-val {
      color: #999999;
      transform: scale(0.85);
      position: absolute;
      right: 5px;
      bottom: 0;

      .del-article-icon {
        height: 25px;
        width: 25px;
        margin-right: 10px;
        color: #ff5c4b;
      }

      img {
        height: 20px;
        width: 20px;
        margin-right: 14px;
      }
      span {
        color: #ff6e01;
        font-size: 22px;
        font-weight: 600;
        margin: 0 5px;
      }
    }

    .source-info {
      color: #999999;
      transform: scale(0.85);
      //  font-size: 12px;
      position: absolute;
      right: 0;
      bottom: 0;

      span {
        margin: 0 5px;
      }
    }
  }

  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }

    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
}
</style>
